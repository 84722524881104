/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  getInvoicedStockByModel,
  getLogisticStockByModel,
  getIncomingOrdersObjectiveByModel
} from 'api/dashboard/kpisByModel'

import { useTokenUuid } from 'hooks/request'
import { useContext, useEffect, useState } from 'react'

import { Card } from 'components/Card'
import { BarChartStacked } from 'components/Graphs/BarChart/BarChartStacked'
import { BarChartDouble } from 'components/Graphs/BarChart/BarChartDouble'
import { getMonthName } from 'api/utils'
import { Button } from 'components/Button/Simple/Button'
import { useNavigate } from 'react-router-dom'
import { BrandContext } from 'contexts/BrandProvider'

export interface SlideParams {
  model: string
  modelImg: string
  modelVariant?: string
}

const Slide = (props: SlideParams) => {
  const { model, modelImg, modelVariant } = props
  const { brand } = useContext(BrandContext)
  const uuid = useTokenUuid()
  const navigate = useNavigate()

  const [incomingOrdersObjective, setIncomingOrdersObjective] = useState<any>()
  const [logisticStock, setLogisticStock] = useState<any>()
  const [invoicedStock, setInvoicedStock] = useState<any>()

  const fetchKPIData = async () => {
    const incomingOrdersObjectiveByModel: any =
      await getIncomingOrdersObjectiveByModel(uuid, model, modelVariant, brand)

    setIncomingOrdersObjective(
      incomingOrdersObjectiveByModel.incoming_orders_objective_kpi
    )

    const invoicedStockByModel: any = await getInvoicedStockByModel(
      uuid,
      model,
      modelVariant,
      brand
    )
    setInvoicedStock(invoicedStockByModel.invoiced_stock_kpi)

    const logisticStockByModel: any = await getLogisticStockByModel(
      uuid,
      model,
      modelVariant,
      brand
    )
    setLogisticStock(logisticStockByModel.logistic_stock_kpi)
  }

  useEffect(() => {
    uuid && fetchKPIData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid])

  return (
    <Card
      headerTitle={
        modelVariant
          ? modelVariant.charAt(0).toUpperCase() +
            modelVariant.slice(1).toLowerCase()
          : model.charAt(0).toUpperCase() + model.slice(1).toLowerCase()
      }
    >
      <>
        <BarChartDouble
          barHeight={190}
          barWidth={300}
          hideLegend={true}
          barColors={['#E18D46', '#F8E5D4', '#A45FCE']}
          data={incomingOrdersObjective}
          dataLast={[
            {
              month: incomingOrdersObjective?.next_month?.month,
              year: incomingOrdersObjective?.next_month?.year,
              deviation_with_current_month_back_orders:
                incomingOrdersObjective?.next_month
                  ?.deviation_with_next_incoming_orders,
              back_orders: incomingOrdersObjective?.next_month?.incoming_orders,
              legend: `${getMonthName(
                incomingOrdersObjective?.next_month?.month
              )}'${incomingOrdersObjective?.next_month?.year
                .toString()
                .substring(2)}`,
              title: 'IO Objective'
            }
          ]}
          dataLegend={
            incomingOrdersObjective
              ? [
                  {
                    month: incomingOrdersObjective?.next_month?.month,
                    year: incomingOrdersObjective?.next_month?.year,
                    deviation_with_current_month_back_orders:
                      incomingOrdersObjective?.next_month
                        ?.deviation_with_next_incoming_orders,
                    back_orders:
                      incomingOrdersObjective?.next_month?.incoming_orders,
                    legend: [
                      incomingOrdersObjective?.next_month?.incoming_orders,
                      'IO Obj',
                      `${getMonthName(
                        incomingOrdersObjective?.next_month?.month
                      )}'${incomingOrdersObjective?.next_month?.year
                        .toString()
                        .substring(2)}`
                    ]
                  },
                  {
                    month: incomingOrdersObjective?.last_year?.month,
                    year: incomingOrdersObjective?.last_year?.year,
                    deviation_with_current_month_back_orders:
                      incomingOrdersObjective?.last_year
                        ?.deviation_with_next_incoming_orders,
                    back_orders:
                      incomingOrdersObjective?.last_year?.incoming_orders,
                    legend: [
                      incomingOrdersObjective?.last_year?.incoming_orders,
                      'IO Closed',
                      `${getMonthName(
                        incomingOrdersObjective?.last_year?.month
                      )}'${incomingOrdersObjective?.last_year?.year
                        .toString()
                        .substring(2)}`
                    ]
                  },
                  {
                    month: incomingOrdersObjective?.current_month?.month,
                    year: incomingOrdersObjective?.current_month?.year,
                    deviation_with_current_month_back_orders:
                      incomingOrdersObjective?.current_month
                        ?.deviation_with_next_incoming_orders,
                    back_orders:
                      incomingOrdersObjective?.current_month?.incoming_orders,
                    legend: [
                      incomingOrdersObjective?.current_month?.incoming_orders,
                      'IO Predicted',
                      `${getMonthName(
                        incomingOrdersObjective?.current_month?.month
                      )}'${incomingOrdersObjective?.current_month?.year
                        .toString()
                        .substring(2)}`
                    ]
                  }
                ]
              : []
          }
        />
        <div style={{ marginTop: '20px' }}>
          <span>Invoiced stock</span>
          <BarChartStacked
            layout="vertical"
            data={invoicedStock}
            total={invoicedStock?.stock}
            width={300}
            hideLegend={true}
          />
        </div>
        <>
          <span>Logistic stock</span>
          <BarChartStacked
            layout="vertical"
            data={logisticStock}
            total={logisticStock?.stock}
            width={300}
            hideLegend={true}
          />
        </>
        {/* <>
            <span>Tactical</span>
            <div></div>
          </>
          <>
            <span>Demand</span>
            <div></div>
          </> */}

        <img
          style={{ display: 'flex', margin: 'auto', maxHeight: '80px' }}
          src={modelImg}
        />
        <div style={{ marginTop: '12px', textAlign: 'center' }}>
          <Button
            onClick={() =>
              navigate(`/dashboard/${model}`, {
                state: {
                  modelVariant: modelVariant
                }
              })
            }
            label="See more"
          />
        </div>
      </>
    </Card>
  )
}

export { Slide }
