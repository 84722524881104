import { api } from 'api/api'

export const getPrivateMarketByModel = async (
  uuid: string,
  model: string,
  modelVariant?: string,
  brand?: string
) => {
  const response = await api.get(
    '/dashboard-kpis/private-market-by-model',
    `brand=${brand}&model=${model}${
      modelVariant ? `&model_variant=${modelVariant}` : ''
    }`,
    uuid,
    1
  )
  return response
}

export const getIncomingOrdersByModel = async (
  uuid: string,
  model: string,
  modelVariant?: string,
  brand?: string
) => {
  const response = await api.get(
    '/dashboard-kpis/incoming-orders',
    `brand=${brand}&model=${model}${
      modelVariant ? `&model_variant=${modelVariant}` : ''
    }`,
    uuid,
    1
  )
  return response
}

export const getIncomingOrdersObjectiveByModel = async (
  uuid: string,
  model: string,
  modelVariant?: string,
  brand?: string
) => {
  const response = await api.get(
    '/dashboard-kpis/incoming-orders-objective',
    `brand=${brand}&model=${model}${
      modelVariant ? `&model_variant=${modelVariant}` : ''
    }`,
    uuid,
    1
  )
  return response
}

export const getInvoicedStockByModel = async (
  uuid: string,
  model: string,
  modelVariant?: string,
  brand?: string
) => {
  const response = await api.get(
    '/dashboard-kpis/invoiced-stock',
    `brand=${brand}&model=${model}${
      modelVariant ? `&model_variant=${modelVariant}` : ''
    }`,
    uuid,
    1
  )
  return response
}

export const getLogisticStockByModel = async (
  uuid: string,
  model: string,
  modelVariant?: string,
  brand?: string
) => {
  const response = await api.get(
    '/dashboard-kpis/logistic-stock',
    `brand=${brand}&model=${model}${
      modelVariant ? `&model_variant=${modelVariant}` : ''
    }`,
    uuid,
    1
  )
  return response
}

export const getProductionByModel = async (
  uuid: string,
  model: string,
  modelVariant?: string,
  brand?: string
) => {
  const response = await api.get(
    '/dashboard-kpis/production',
    `brand=${brand}&model=${model}${
      modelVariant ? `&model_variant=${modelVariant}` : ''
    }`,
    uuid,
    1
  )
  return response
}

export const getBackOrdersByModel = async (
  uuid: string,
  model: string,
  modelVariant?: string,
  brand?: string
) => {
  const response = await api.get(
    '/dashboard-kpis/back-orders',
    `brand=${brand}&model=${model}${
      modelVariant ? `&model_variant=${modelVariant}` : ''
    }`,
    uuid,
    1
  )
  return response
}
