import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'

// SEAT IMG
import IbizaImg from '../assets/ibiza.png'
import AronaImg from '../assets/arona.png'
import Leon5dImg from '../assets/leon-5d.png'
import LeonStImg from '../assets/leon-st.png'
import AtecaImg from '../assets/ateca.png'
import TarracoImg from '../assets/tarraco.png'

// CUPRA IMG
import CupraAtecaImg from '../assets/cupra-ateca.png'
import CupraBornImg from '../assets/cupra-born.png'
import CupraFormentorImg from '../assets/cupra-formentor.png'
import CupraLeon5dImg from '../assets/cupra-leon-5d.png'
import CupraLeonStImg from '../assets/cupra-leon-st.png'
import CupraTavascanImg from '../assets/cupra-tavascan.png'
import CupraTerramarImg from '../assets/cupra-terramar.png'

import { Slide } from './Slide'
import { BrandContext } from 'contexts/BrandProvider'
import { useContext } from 'react'

const Carousel = () => {
  const { brand } = useContext(BrandContext)

  return (
    <>
      <Swiper
        slidesPerView={4}
        centeredSlides={false}
        spaceBetween={20}
        grabCursor={true}
        navigation={true}
        modules={[Navigation]}
        style={{ width: '100%', height: '100%' }}
      >
        {brand === 'seat' && (
          <>
            <SwiperSlide>
              <Slide model="ibiza" modelImg={IbizaImg} />
            </SwiperSlide>
            <SwiperSlide>
              <Slide model="arona" modelImg={AronaImg} />
            </SwiperSlide>
            <SwiperSlide>
              <Slide model="leon" modelVariant="leon-5d" modelImg={Leon5dImg} />
            </SwiperSlide>
            <SwiperSlide>
              <Slide model="leon" modelVariant="leon-st" modelImg={LeonStImg} />
            </SwiperSlide>
            <SwiperSlide>
              <Slide model="ateca" modelImg={AtecaImg} />
            </SwiperSlide>
            <SwiperSlide>
              <Slide model="tarraco" modelImg={TarracoImg} />
            </SwiperSlide>
          </>
        )}

        {brand === 'cupra' && (
          <>
            <SwiperSlide>
              <Slide model="ateca" modelImg={CupraAtecaImg} />
            </SwiperSlide>
            <SwiperSlide>
              <Slide model="born" modelImg={CupraBornImg} />
            </SwiperSlide>
            <SwiperSlide>
              <Slide model="formentor" modelImg={CupraFormentorImg} />
            </SwiperSlide>
            <SwiperSlide>
              <Slide
                model="leon"
                modelVariant="leon-5d"
                modelImg={CupraLeon5dImg}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Slide
                model="leon"
                modelVariant="leon-st"
                modelImg={CupraLeonStImg}
              />
            </SwiperSlide>
            <SwiperSlide>
              <Slide model="tavascan" modelImg={CupraTavascanImg} />
            </SwiperSlide>
            <SwiperSlide>
              <Slide model="terramar" modelImg={CupraTerramarImg} />
            </SwiperSlide>
          </>
        )}
      </Swiper>
    </>
  )
}

export { Carousel }
