/* eslint-disable react-hooks/exhaustive-deps */
import { Filters } from 'components/Filters'
import { Datepicker } from 'components/Inputs/Datepicker'
import { Select } from 'components/Inputs/Select'
import {
  useRef,
  MutableRefObject,
  useCallback,
  useState,
  useEffect,
  useContext
} from 'react'
import { usePocketStore } from 'state'
import { arrayObjectsAreSame, getDateForNextMonths } from 'utils'
import { shallow } from 'zustand/shallow'
import { BrandContext } from 'contexts/BrandProvider'
import {
  getFilterBasket,
  getFilterFuel,
  getFilterUniversalTrim
} from 'api/pricing/pocket'
import { toQueryParams } from 'api/utils'
import { useTokenUuid } from 'hooks/request'

const PageFilters = ({
  handleFilters,
  maximunDate,
  selectedModel,
  handleEnable
}) => {
  const [modelList, setModelList] = useState<any[]>([])
  const [fuelList, setFuelList] = useState<any[]>([])

  const maxDate = maximunDate ?? getDateForNextMonths(1)
  const modelRef: MutableRefObject<any> = useRef('model')
  const fuelRef: MutableRefObject<any> = useRef('fuel')
  const dateRef: MutableRefObject<any> = useRef('date')

  const { brand } = useContext(BrandContext)
  const uuid = useTokenUuid()

  const { model, fuel, updateFilter, resetFilter, startMonth, startYear } =
    usePocketStore(
      (state: any) => ({
        model: state.inputForecastModel,
        fuel: state.inputForecastfuel,
        startMonth: state.startMonth,
        startYear: state.startYear,
        updateFilter: state.updateFilter,
        resetFilter: state.resetFilter
      }),
      shallow
    )

  const today = maximunDate

  const [valueModel, setValueModel] = useState(model)
  const [valueFuel, setValueFuel] = useState(fuel)
  const [valueYear, setValueYear] = useState(today.getFullYear())
  const [valueMonth, setValueMonth] = useState(today.getMonth() + 1)
  const [valueDate, setValueDate] = useState(today)
  const [isApplyDisabled, setIsApplyDisabled] = useState(true)

  const [universalTrim, setUniversalTrim] = useState({})

  const updateStateFilter = useCallback((key: string, value: any) => {
    switch (key) {
      case 'date':
        if (value) {
          setValueYear(new Date(value).getFullYear())
          setValueMonth(new Date(value).getMonth() + 1)
          setValueDate(new Date(value))
        }
        break
      case 'model':
        if (value) setValueModel(value)
        break
      case 'fuel':
        if (value) {
          applyAll()
          updateFilter('inputForecastFuel', value)
          setValueFuel(value.map((el) => el.value))
        }
        break
    }
  }, [])

  const applyAll = useCallback(() => {
    updateFilter('inputForecastPeriodStart', valueDate)
    updateFilter('inputForecastModel', valueModel)
    updateFilter('inputForecastFuel', valueFuel)
    setIsApplyDisabled(true)
  }, [updateFilter, valueModel, valueFuel, valueDate])

  const clearAll = useCallback(() => {
    modelRef.current.clearValue()
    fuelRef.current.clearValue()
    resetFilter('inputForecastPeriodStart')
    resetFilter('inputForecastModel')
    resetFilter('inputForecastFuel')
  }, [resetFilter])

  const enableApplyAll = useCallback(() => {
    return (
      valueModel &&
      valueMonth &&
      valueYear &&
      valueFuel &&
      valueFuel?.length !== 0 &&
      !(
        arrayObjectsAreSame(model, valueModel) &&
        startMonth === valueMonth &&
        startYear === valueYear &&
        arrayObjectsAreSame(fuel, valueFuel)
      )
    )
  }, [
    model,
    valueModel,
    startMonth,
    valueMonth,
    startYear,
    valueYear,
    fuel,
    valueFuel
  ])

  const totalFilters = useCallback(() => {
    const hasModel = +!!model?.model
    const hasfuel = +!!fuel?.value
    const hasMonth = +!!startMonth
    const hasYear = +!!startYear
    return hasModel + hasfuel + hasMonth + hasYear
  }, [model?.model, fuel?.value, startMonth, startYear])

  const fetchFilterBasekt = async () => {
    let queryParams = toQueryParams({
      year: valueYear,
      month: valueMonth,
      basket_brand: brand
    })
    const resp: any = await getFilterBasket(uuid, queryParams)

    if (resp !== 404 && resp !== 204 && resp !== 500) {
      const formattedList = resp.basket_filters.map((model: any) => ({
        value: model.value,
        label: model.label
      }))
      setModelList(formattedList)

      if (selectedModel) {
        const matchedModel = formattedList.find(
          (model) => model.value === selectedModel
        )

        setValueModel(
          matchedModel || {
            value: selectedModel,
            label:
              selectedModel.charAt(0).toUpperCase() +
              selectedModel.slice(1).toLowerCase()
          }
        )
      }
    }
  }

  const fetchFilterFuel = async () => {
    if (valueModel) {
      let queryParams = toQueryParams({
        year: valueYear,
        month: valueMonth,
        basket_brand: brand,
        basket: valueModel.value
      })
      const resp: any = await getFilterFuel(uuid, queryParams)
      setFuelList(resp.fuel_filters)
    }
  }

  const fetchUniversalTrim = async () => {
    if (valueModel && valueFuel) {
      let queryParams = toQueryParams({
        basket: valueModel.value,
        month: valueMonth,
        year: valueYear,
        fuels: valueFuel,
        basket_brand: brand
      })

      const resp: any = await getFilterUniversalTrim(uuid, queryParams)

      setUniversalTrim(resp)
    }
  }

  useEffect(() => {
    handleFilters(universalTrim)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [universalTrim])

  useEffect(() => {
    uuid && fetchFilterBasekt()
    modelRef.current.clearValue()
    fuelRef.current.clearValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, valueYear, valueMonth])

  useEffect(() => {
    setUniversalTrim([])
    setFuelList([])
    setValueFuel([])
    fuelRef.current.clearValue()
    if (valueModel && valueModel.value) uuid && fetchFilterFuel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, valueModel])

  useEffect(() => {
    setIsApplyDisabled(false)
    if (valueFuel && valueFuel.length > 0) uuid && fetchUniversalTrim()
    if (valueFuel && valueFuel.length === 0) setUniversalTrim([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, valueFuel])

  useEffect(() => {
    handleEnable(isApplyDisabled)
  }, [isApplyDisabled])

  useEffect(() => {
    clearAll()
    setValueModel([])
    setFuelList([])
    setValueFuel([])
    modelRef.current.clearValue()
    fuelRef.current.clearValue()
    uuid && fetchFilterBasekt()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, brand])

  useEffect(() => {
    if (selectedModel) {
      setValueModel({
        value: selectedModel,
        label:
          selectedModel.charAt(0).toUpperCase() +
          selectedModel.slice(1).toLowerCase()
      })
    }
  }, [selectedModel])

  return (
    <Filters
      applyAll={applyAll}
      clearAll={clearAll}
      enableApplyAll={!isApplyDisabled && enableApplyAll()}
      enableClearAll={false}
      totalFilters={totalFilters()}
    >
      <Datepicker
        maxDate={maxDate}
        emptyPlaceholder="Select month"
        reference={dateRef}
        defaultValue={today}
        onUpdateValue={updateStateFilter}
        fixedWidth="210px"
        id="date"
      />

      <Select
        options={modelList}
        emptyPlaceholder="Model"
        reference={modelRef}
        value={valueModel}
        onUpdateValue={updateStateFilter}
        id="model"
      />
      <Select
        options={fuelList}
        emptyPlaceholder="Fuel"
        reference={fuelRef}
        defaultValue={fuel}
        onUpdateValue={updateStateFilter}
        id="fuel"
        isDisabled={valueModel === null}
        isMulti
      />
    </Filters>
  )
}

export { PageFilters }
