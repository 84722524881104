/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from 'layout/Content/Content.style'
import { useContext, useEffect, useState } from 'react'
import { BrandContext } from 'contexts/BrandProvider'
import { SEAT_THEME } from '../../constants/themes'
import { Title } from 'components/Title'
import { Row } from 'components/Grid/Row'
import { Col } from 'components/Grid/Col'

import { useTokenUuid } from 'hooks/request'
import {
  getInvoicedStock,
  getLogisticStock,
  getPrivateMarket,
  getProduction,
  getBackOrders,
  getIncomingOrders,
  getIncomingOrdersObjective
} from 'api/dashboard/kpi'
import { Spinner } from 'components/Spinner'
import { getMonthName, toQueryParams } from 'api/utils'

import { PrivateMarket } from './Components/PrivateMarket'
import { IncomingOrders } from './Components/IncomingOrders'
import { IncomingOrdersObjective } from './Components/IncomingOrdersObjective'
import { InvoicedStock } from './Components/InvoicedStock'
import { LogisticStock } from './Components/LogisticStock'
import { Production } from './Components/Production'
import { BackOrders } from './Components/BackOrders'
import { Carousel } from './Components/Carousel'
import { capitalize } from 'utils'

const Dashboard = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [privateMarket, setPrivateMarket] = useState<any>({})
  const [incomingOrders, setIncomingOrders] = useState<any>({})
  const [incomingOrdersObjective, setIncomingOrdersObjective] = useState<any>(
    {}
  )
  const [invoicedStock, setInvoicedStock] = useState<any>({})
  const [logisticStock, setLogisticStock] = useState<any>({})
  const [production, setProduction] = useState<any>({})
  const [backOrders, setBackOrders] = useState<any>({})

  const { brand, setBrand } = useContext(BrandContext)

  const uuid = useTokenUuid()

  const fetchKPIData = async () => {
    setIsLoading(true)
    const queryParams = toQueryParams({
      brand
    })

    //KPI 1: Mercado Privado
    const privateMarketKPI: any = await getPrivateMarket(uuid, queryParams)
    setPrivateMarket(privateMarketKPI?.private_market_kpi)

    //KPI 2: Incoming orders
    const incomingOrdersKPI: any = await getIncomingOrders(uuid, queryParams)
    setIncomingOrders(incomingOrdersKPI?.incoming_orders_kpi)

    //KPI 3: Next Incoming Orders Objective
    const incomingOrdersObjectiveKPI: any = await getIncomingOrdersObjective(
      uuid,
      queryParams
    )
    setIncomingOrdersObjective(
      incomingOrdersObjectiveKPI?.incoming_orders_objective_kpi
    )

    //KPI 4:Invoiced Stock
    const invoicedStockKPI: any = await getInvoicedStock(uuid, queryParams)
    setInvoicedStock(invoicedStockKPI?.invoiced_stock_kpi)

    //KPI 5:Logistic Stock
    const logisticStockKPI: any = await getLogisticStock(uuid, queryParams)
    setLogisticStock(logisticStockKPI?.logistic_stock_kpi)

    //KPI 6: Production
    const productionKPI: any = await getProduction(uuid, queryParams)
    setProduction(productionKPI?.production_kpi)

    //KPI 7: Back orders
    const backOrdersKPI: any = await getBackOrders(uuid, queryParams)
    setBackOrders(backOrdersKPI?.back_orders_kpi)

    setIsLoading(false)
  }

  useEffect(() => {
    uuid && fetchKPIData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uuid, brand])

  return (
    <>
      {isLoading && <Spinner />}
      <Container>
        <Row align="center" justify="space-between">
          <Title
            label={`Dashboard overview - ${capitalize(brand)}`}
            data-testid="dashboard-view"
          />
          {privateMarket && (
            <p>{`Data from ${getMonthName(privateMarket?.latest_month + 1)}'${
              privateMarket?.latest_year
            } (private channel)`}</p>
          )}
        </Row>

        <Row>
          {privateMarket && (
            <Col>
              <PrivateMarket privateMarket={privateMarket} />
            </Col>
          )}

          {incomingOrders && (
            <Col>
              <IncomingOrders incomingOrders={incomingOrders} />
            </Col>
          )}

          {incomingOrdersObjective && (
            <Col>
              <IncomingOrdersObjective
                incomingOrdersObjective={incomingOrdersObjective}
              />
            </Col>
          )}
        </Row>
        <Row>
          {invoicedStock && (
            <Col>
              <InvoicedStock invoicedStock={invoicedStock} />
            </Col>
          )}
          {logisticStock && (
            <Col>
              <LogisticStock logisticStock={logisticStock} />
            </Col>
          )}

          {/* <Col>
            <Production production={production} />
          </Col> */}
          {backOrders && (
            <Col>
              <BackOrders backOrders={backOrders} />
            </Col>
          )}
        </Row>
        <Row>
          <Carousel />
        </Row>
      </Container>
    </>
  )
}

export { Dashboard }
