export const toQueryParams = (params) => {
  const queryParams = Object.entries(params).reduce((acc, obj, index) => {
    const [key, value] = obj
    if (typeof value === 'undefined') {
      return acc
    }
    if (typeof value === 'object') {
      return `${acc}${index === 0 ? '' : '&'}${key}=${value?.toString()}`
    }
    return `${acc}${index === 0 ? '' : '&'}${key}=${value}`
  }, '')

  return queryParams
}

export const getMonthName = (month: number) => {
  const monthName = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ]
  return monthName[month - 1]
}

export const getMonthFullName = (month: number) => {
  const monthName = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
  return monthName[month - 1]
}

export const getModelColor = (model: string) => {
  switch (model) {
    case 'ibiza':
      return '#D40028'
    case 'arona':
      return '#3AD4A7'
    case 'leon':
      return '#22283B'
    case 'ateca':
      return '#1F422C'
    case 'tarraco':
      return '#DED1A7'
    default:
      return '#000'
  }
}

// Seat imgs
import Ibiza from '../views/Dashboard/assets/ibizaw.png'
import Arona from '../views/Dashboard/assets/aronab.png'
import Leon5d from '../views/Dashboard/assets/leon-5db.png'
import LeonSt from '../views/Dashboard/assets/leon-stb.png'
import Ateca from '../views/Dashboard/assets/atecab.png'
import Tarraco from '../views/Dashboard/assets/tarracob.png'

// Cupra imgs
import CupraAteca from '../views/Dashboard/assets/cupra-ateca.png'
import CupraBorn from '../views/Dashboard/assets/cupra-born.png'
import CupraFormentor from '../views/Dashboard/assets/cupra-formentor.png'
import CupraLeon5d from '../views/Dashboard/assets/cupra-leon-5d.png'
import CupraLeonSt from '../views/Dashboard/assets/cupra-leon-st.png'
import CupraTavascan from '../views/Dashboard/assets/cupra-tavascan.png'
import CupraTerramar from '../views/Dashboard/assets/cupra-terramar.png'

export const getModelImage = (model: string, brand: string) => {
  if (brand === 'seat') {
    switch (model) {
      case 'ibiza':
        return Ibiza
      case 'arona':
        return Arona
      case 'leon-5d':
        return Leon5d
      case 'leon-st':
        return LeonSt
      case 'ateca':
        return Ateca
      case 'tarraco':
        return Tarraco
      default:
        return Ibiza
    }
  }

  if (brand === 'cupra') {
    switch (model) {
      case 'ateca':
        return CupraAteca
      case 'born':
        return CupraBorn
      case 'formentor':
        return CupraFormentor
      case 'leon-5d':
        return CupraLeon5d
      case 'leon-st':
        return CupraLeonSt
      case 'tavascan':
        return CupraTavascan
      case 'terramar':
        return CupraTerramar
      default:
        return Ibiza
    }
  }
}
