import styled from 'styled-components'
import DatePicker from 'react-datepicker'

export const Wrapper = styled.div<{
  fullWidth: boolean
  look?: 'gray'
  fixedWidth?: string
  readOnly?: boolean
}>`
  padding: 0;
  border-radius: 0;
  width: ${({ fullWidth }) => fullWidth && '100%'};
  .datepicker {
    font-weight: normal;
    align-items: center;
    display: flex;
    color: ${({ theme }) => theme.colors.black};
    font-size: 1.4rem;
    font-family: ${({ theme }) => theme.fonts};
    position: relative;
    input {
      padding: 0 1.2rem;
      border: 1px solid ${({ theme }) => theme.colors.border};
      height: ${({ look }) => (look === 'gray' ? '4rem' : '3.4rem')};
      cursor: ${({ readOnly }) => (readOnly ? 'no-drop' : 'pointer')};
      font-size: 1.4rem;
    }
    &:before {
      border-color: ${({ theme }) => theme.colors.primary};
      border-style: solid;
      border-width: 0.18rem 0.18rem 0 0;
      content: '';
      display: block;
      height: 0.5rem;
      position: absolute;
      top: 1.2rem;
      right: 1.6rem;
      width: 0.5rem;
      transform: rotate(135deg);
      z-index: 0;
      zoom: ${({ look }) => look === 'gray' && 1.15};
    }
  }
  .react-datepicker {
    position: relative;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.border};
    margin-top: 0.5rem;
  }

  .react-datepicker__month {
    display: flex;
    flex-direction: column;
    padding: 0.9rem;
  }
  .react-datepicker__month-wrapper {
    display: flex;
    justify-content: left;
    width: 100%;
  }
  .react-datepicker__month-text {
    flex: 1 1 33.33%;
    max-width: 33.33%;
    width: 33.33%;
    font-size: ${({ look }) => look === 'gray' && '1.6rem'};
    padding: ${({ look }) =>
      look === 'gray' ? '1.1rem 2.4rem' : '0.9rem 2rem'};
    cursor: pointer;
    font-weight: normal;
    &:hover {
      background-color: ${({ theme }) => theme.colors.primaryEvent};
    }
  }
  .react-datepicker__month--selected {
    font-weight: 500;
    background-color: ${({ theme }) => theme.colors.primaryEvent};
    &:hover {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }
  .react-datepicker__month--disabled {
    color: ${({ theme }) => theme.colors.border};
    cursor: not-allowed;
    &:hover {
      background-color: ${({ theme }) => theme.colors.white};
    }
  }
  .react-datepicker__header {
    text-align: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.border};
    padding: ${({ look }) => (look === 'gray' ? '1.2rem' : '1rem')};
    font-size: ${({ look }) => look === 'gray' && '1.6rem'};
  }
  .react-datepicker__navigation--previous {
    position: absolute;
    left: 1rem;
    top: 1rem;
  }
  .react-datepicker__navigation--next {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }
  .react-datepicker__navigation {
    align-items: center;
    background: none;
    display: flex;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 2px;
    padding: 0;
    border: none;
    z-index: 1;
    height: 32px;
    width: 32px;
    text-indent: -999em;
    overflow: hidden;
  }
  .react-datepicker__navigation-icon:before {
    border-color: ${({ theme }) => theme.colors.black};
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: '';
    display: block;
    height: 9px;
    position: absolute;
    top: 1rem;
    width: 0.9rem;
  }
  .react-datepicker__navigation-icon--previous:before {
    transform: rotate(225deg);
  }
  .react-datepicker__navigation-icon--next:before {
    transform: rotate(45deg);
  }
  .react-datepicker__input-container {
    width: ${({ fullWidth }) => fullWidth && '100%'};
    input {
      caret-color: transparent;
      width: ${({ fixedWidth }) => fixedWidth};
      background-color: ${({ look, theme }) =>
        look === 'gray' && theme.colors.bgPage};
      padding: ${({ look }) => look === 'gray' && '0 2.4rem'};
      font-size: ${({ look }) => look === 'gray' && '1.6rem'};
      color: ${({ look, theme }) => look === 'gray' && theme.colors.gray};
    }
  }
  .react-datepicker__aria-live {
    display: none;
  }
`

export const ReactDatePicker = styled(DatePicker)``
