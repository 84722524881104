import {
  POCKET_ANALYSIS,
  DEMAND_FORECAST,
  DASHBOARD,
  PRICING,
  SMART_ORDER,
  SMART_ORDER_QUALITATIVE,
  SMART_ORDER_QUANTITATIVE,
  SMART_ORDER_RECOMMENDATION_ORDER,
  DATA_FORECAST_INSIGHTS,
  MTF_COMPARATIVE,
  SMART_ORDER_DEMAND_FORECAST
} from 'constants/paths'
import { t } from 'i18next'
import { useState } from 'react'
import {
  InternalNavLink,
  NavItem,
  NavList,
  NavTitle,
  NavSubmenu,
  Wrapper,
  SubmenuItem
} from './CustomHeader.style'
import { useLocation } from 'react-router-dom'

const CustomHeader = () => {
  const [isMenuHovered, setIsMenuHovered] = useState('')
  const { pathname } = useLocation()

  const menuItems = [
    {
      title: 'Dashboard',
      link: DASHBOARD
    },
    {
      title: 'Intelligent Pricing',
      subitems: [
        {
          anchor: t('sidebar.pricing.mtfComparative'),
          link: PRICING + '/' + MTF_COMPARATIVE
        },
        {
          anchor: t('sidebar.pricing.pocketAnaylsis'),
          link: PRICING + '/' + POCKET_ANALYSIS
        },
        {
          anchor: t('sidebar.pricing.dataForecastInsights'),
          link: PRICING + '/' + DATA_FORECAST_INSIGHTS
        },
        {
          anchor: t('sidebar.pricing.demandForecast'),
          link: PRICING + '/' + DEMAND_FORECAST
        }
      ]
    },
    {
      title: 'Smart Order',
      subitems: [
        {
          anchor: t('sidebar.smartOrder.quantitative'),
          link: SMART_ORDER + '/' + SMART_ORDER_QUANTITATIVE
        },
        {
          anchor: t('sidebar.smartOrder.orderList'),
          link: SMART_ORDER + '/' + SMART_ORDER_RECOMMENDATION_ORDER
        },
        {
          anchor: t('sidebar.smartOrder.qualitative'),
          link: SMART_ORDER + '/' + SMART_ORDER_QUALITATIVE
        },
        {
          anchor: t('sidebar.smartOrder.demand'),
          link: SMART_ORDER + '/' + SMART_ORDER_DEMAND_FORECAST
        }
      ]
    }
  ]

  return pathname === '/' ? null : (
    <Wrapper data-testid="appheader-bottom-component">
      <NavList>
        {menuItems.map((item) => (
          <NavItem
            key={item.title}
            onMouseEnter={() => setIsMenuHovered(item.title)}
            onMouseLeave={() => setIsMenuHovered('')}
          >
            {item.link && (
              <InternalNavLink to={item.link}>{item.title}</InternalNavLink>
            )}
            {!item.link && <NavTitle>{item.title}</NavTitle>}

            {isMenuHovered === item.title && (
              <NavSubmenu>
                {item?.subitems?.map((subitem) => (
                  <SubmenuItem key={subitem.anchor}>
                    <InternalNavLink to={subitem.link}>
                      {subitem.anchor}
                    </InternalNavLink>
                  </SubmenuItem>
                ))}
              </NavSubmenu>
            )}
          </NavItem>
        ))}
      </NavList>
    </Wrapper>
  )
}

export { CustomHeader }
