import { Link } from 'react-router-dom'
import styled, { css, StyledProps } from 'styled-components'
import { GridType } from './Card.types'

export const CardWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.name === 'seat-theme' && '0.6rem'};
  box-shadow: 0px 12px 22px hsl(0deg 0% 0% / 10%);
  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
  }
`

export const CardHeader = styled.div`
  padding: 12px 1.6rem;
  align-items: center;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #f5f5f5;
`

export const CardHeaderTitle = styled.span<{ upperCaseTitle: boolean }>`
  font-weight: 500;
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.black};
  ${({ upperCaseTitle }) =>
    upperCaseTitle &&
    `
    text-transform: uppercase;
  `}
`

export const CardHeaderSubtitle = styled.span`
  font-size: 12px;
  color: #999999;
  display: block;
  margin-top: 4px;
`

export const CardFooter = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.border};
  min-height: 5.2rem;
  display: flex;
  align-items: center;
  padding: 0 1.6rem;
  width: 100%;
  font-weight: normal;
  font-size: 1.3rem;
  color: #6a6a6a;
`

export const CardGridWrapper = styled.div`
  width: 100%;
`

export const TooltipContainer = styled.div`
  position: relative;
  cursor: help;

  path {
    fill: #999;
  }

  > .tooltip {
    padding: 12px;
    text-align: left;
    > p {
      font-size: 12px;
    }
  }
`

const gridType = (props: StyledProps<{ gridType: GridType }>) => {
  switch (props.gridType) {
    case 'full':
      return fullGrid
    case 'default':
      return defaultGrid
  }
}

const fullGrid = css`
  padding: 0;
`

const defaultGrid = css`
  padding: 1.4rem 1.6rem;
`

export const CardContent = styled.div<{
  gridType: GridType
  minHeightBody: number
}>`
  ${gridType};
  width: 100%;
  height: 100%;
  min-height: ${({ minHeightBody }) => minHeightBody + 'rem'};
`

export const RouterLink = styled(Link)`
  font-weight: 500;
  padding: 1rem 1rem;
  margin-right: -1rem;
`

export const InputSearch = styled.div`
  margin-left: auto;
  margin-right: 40px;

  label {
    margin-bottom: 4px;
    font-size: 12px;
    font-weight: normal;
    color: rgb(153, 153, 153);
  }
`

export const SelectContainer = styled.div`
  margin-left: auto;
  margin-right: 40px;
`
