import { Card } from 'components/Card'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorFallback } from 'components/ErrorFallback'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  ZAxis,
  ScatterChart,
  Tooltip,
  Scatter,
  Dot,
  LabelList
} from 'recharts'
import { useEffect, useState } from 'react'
import { Item, LegendWrapper } from './BubbleChart.styles'
import { getPocketTableInfo } from 'api/pricing/pocket'
import { useTokenUuid } from 'hooks/request'
import { toQueryParams } from 'api/utils'
import { SimpleTable } from 'components/Table'
import { Flex } from 'App.style'
import { usePocketDataTable, usePocketParams } from '../hooks/usePocketParams'
import { CustomTooltip } from './CustomTooltip'
import { generateConsistentColors } from '../utils'

const BubbleChart = ({ pocket }) => {
  const [isTableVisible, setIsTableVisible] = useState<boolean>(false)
  const [dataTable, setDataTable] = useState([])

  const params = usePocketParams()

  const uuid = useTokenUuid()

  const dataTableFormatted = usePocketDataTable(dataTable)

  const handleOnClick = async (el) => {
    let queryParams = toQueryParams({
      year: params.startYear,
      month: params.startMonth - 1,
      brand: el.brand,
      model: el.model,
      fuel: el.fuel,
      universal_trim: el.universal_trim
    })
    const resp: any = await getPocketTableInfo(uuid, queryParams)
    if (resp !== 204 && resp !== 500) {
      setIsTableVisible(true)
      setDataTable(resp)
    }
  }

  const renderLegend = (props) => {
    const { payload } = props

    return (
      <LegendWrapper style={{ display: 'flex' }}>
        {payload.map((entry, index) => (
          <li key={`item-${index}`}>{entry.value}</li>
        ))}
        <li>Registrations</li>
      </LegendWrapper>
    )
  }

  const CustomizedShape: any = ({ cx, cy, scaled_volume_px, index }) => {
    const generatedColors = generateConsistentColors(100)

    return (
      <Dot
        cx={cx}
        cy={cy}
        r={scaled_volume_px / 2}
        cursor="pointer"
        fill={generatedColors[index % generatedColors.length]}
        style={{ opacity: '0.5' }}
      />
    )
  }
  const indexedData = pocket.map((item, index) => ({
    ...item,
    index
  }))

  useEffect(() => {
    setIsTableVisible(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pocket])

  return (
    <Flex $alignItems="baseline">
      <Item>
        <Card
          headerTitle="Pocket analysis"
          minHeightBody={43.6}
          subtitle="Pocket visualization comparing the model with its competitors in terms of price, equipment, and registrations volume"
          tooltip={
            <p>
              Pocket visualization comparing the model with its competitors in
              terms of price, equipment, and registrations volume
            </p>
          }
        >
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ResponsiveContainer width="100%" height="100%">
              <ScatterChart
                width={730}
                height={250}
                margin={{
                  top: 20,
                  right: 20,
                  bottom: 40,
                  left: 40
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="adjustments"
                  type="number"
                  name="Adjustment"
                  domain={([dataMin, dataMax]) => {
                    return [dataMin - 1000, dataMax + 1000]
                  }}
                  label={{
                    value: 'Adjustment',
                    position: 'bottom'
                  }}
                />
                <YAxis
                  dataKey="transactional_price"
                  type="number"
                  name="Transactional Price"
                  unit="€"
                  domain={([dataMin, dataMax]) => {
                    return [
                      Math.floor(dataMin - dataMin * 0.15),
                      Math.floor(dataMax + dataMax * 0.15)
                    ]
                  }}
                  label={{
                    value: 'Transactional Price',
                    angle: -90,
                    position: 'left',
                    offset: 20
                  }}
                />
                <ZAxis
                  dataKey="scaled_volume_px"
                  type="number"
                  name="scaled_volume_px"
                />
                <Tooltip
                  cursor={{ strokeDasharray: '2 2' }}
                  content={<CustomTooltip />}
                />
                <Legend
                  content={renderLegend}
                  wrapperStyle={{ left: 0, bottom: 0 }}
                />

                <Scatter
                  name="Competitors"
                  data={indexedData}
                  onClick={(el) => handleOnClick(el)}
                  shape={<CustomizedShape />}
                >
                  <LabelList dataKey="model" position="bottom" fill="#000" />
                </Scatter>
              </ScatterChart>
            </ResponsiveContainer>
          </ErrorBoundary>
        </Card>
      </Item>
      <Item>
        <div style={{ display: 'block' }}>
          {isTableVisible &&
            dataTableFormatted &&
            dataTableFormatted.data.length > 0 && (
              <Card headerTitle={dataTableFormatted.title}>
                <SimpleTable {...dataTableFormatted} />
              </Card>
            )}
        </div>
      </Item>
    </Flex>
  )
}

export { BubbleChart }
