import { useDemandStore } from 'state'
import { shallow } from 'zustand/shallow'
import { sortTableFn } from 'components/Table/utils'
import { useMemo } from 'react'

export const useMergePoints = (
  points1?: any,
  points2?: any,
  points3?: any,
  points4?: any,
  points5?: any,
  points6?: any
) => {
  const merged = {}

  const addPoints = (points, key) => {
    points.forEach((point) => {
      const { month, year, value, interval } = point
      const id = `${month}-${year}`
      if (!merged[id]) {
        merged[id] = { month, year, interval: [0, 0], regression: 0 }
      }
      merged[id][key] = value
      if (key === 'orders_points') {
        merged[id].interval[0] += interval[0]
        merged[id].interval[1] += interval[1]
      }
    })
  }

  addPoints(points1, 'cars_in_stock_points')
  addPoints(points2, 'confs_points')
  addPoints(points3, 'corrected_discount_points')
  addPoints(points4, 'total_budget_points')
  addPoints(points5, 'traffic_shw_points')
  addPoints(points6, 'orders_points')

  return Object.values(merged).map((point: any) => {
    return point
  })
}

export const useMergePointsSO = (
  points1?: any,
  points2?: any,
  points3?: any
) => {
  const merged = {}

  const addPoints = (points, key) => {
    points.forEach((point) => {
      const { month, year, value, interval } = point
      const id = `${month}-${year}`
      if (!merged[id]) {
        merged[id] = { month, year, interval: [0, 0], regression: 0 }
      }
      merged[id][key] = value
      if (key === 'predicted_order_points') {
        merged[id].interval[0] += interval[0]
        merged[id].interval[1] += interval[1]
      }
    })
  }

  addPoints(points1, 'sales_points')
  addPoints(points2, 'predicted_order_points')
  addPoints(points3, 'objectives_sales_points')

  return Object.values(merged).map((point: any) => {
    return point
  })
}

export const useDemandForecatParams = () => {
  const { inputForecastModel, inputForecastMTF } = useDemandStore(
    (state: any) => ({
      inputForecastModel: state.inputForecastModel,
      inputForecastMTF: state.inputForecastMTF
    }),
    shallow
  )

  const queryModel = inputForecastModel?.value?.toLowerCase()
  const queryMTF = inputForecastMTF

  return {
    model: queryModel,
    mtf: queryMTF
  }
}

export const useDemandForecastTableData = (data: any) => {
  const tableData = data?.map(
    ({
      model_variant,
      fuel,
      trim,
      cars_in_stock,
      confs,
      corrected_discount,
      total_budget,
      traffic_shw
    }) => {
      if (model_variant && fuel && trim) `${model_variant} ${fuel} ${trim}`

      const modelFuelTrim =
        model_variant && fuel && trim
          ? `${model_variant} ${fuel} ${trim}`
          : model_variant

      return {
        modelFuelTrim,
        model_variant,
        fuel,
        trim,
        cars_in_stock,
        confs,
        corrected_discount,
        total_budget,
        traffic_shw
      }
    }
  )

  const columns = useMemo(
    () =>
      data && [
        {
          accessorKey: 'modelFuelTrim',
          header: 'Model',
          columnAlign: 'left',
          sortingFn: sortTableFn
        },
        {
          accessorKey: 'cars_in_stock',
          header: 'Cars in Stock',
          columnAlign: 'right',
          sortingFn: sortTableFn
        },
        {
          accessorKey: 'confs',
          header: 'Web Confs',
          columnAlign: 'right',
          sortingFn: sortTableFn
        },
        {
          accessorKey: 'corrected_discount',
          header: 'Corrected Discount',
          columnAlign: 'right',
          sortingFn: sortTableFn,
          size: 200
        },
        {
          accessorKey: 'total_budget',
          header: 'Total Marketing Budget (K)',
          columnAlign: 'right',
          sortingFn: sortTableFn
        },
        {
          accessorKey: 'traffic_shw',
          header: 'Traffic Show',
          columnAlign: 'right',
          sortingFn: sortTableFn
        }
      ],
    [data]
  )

  return {
    columns,
    data: tableData,
    tableId: 'demandForecastTable'
  }
}

export const useMostRecentDate = (
  data: any
): { month: number; year: number } => {
  let mostRecent = data[0]

  for (const entry of data) {
    if (
      entry.year > mostRecent.year ||
      (entry.year === mostRecent.year && entry.month > mostRecent.month)
    ) {
      mostRecent = entry
    }
  }

  return { month: mostRecent.month, year: mostRecent.year }
}
