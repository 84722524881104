// INTELLIGENT SALES
export const INTELLIGENT_SALES: string = '/'

// SMART ORDER
export const SMART_ORDER: string = 'smart-order'
export const SMART_ORDER_SALES_FORECAST: string = 'sales-forecast'
export const SMART_ORDER_STOCK_DETAILS: string = 'stock-details'
export const SMART_ORDER_MAIN_KPIS: string = 'main-kpis'
export const SMART_ORDER_FU_CALENDAR: string = 'fu-calendar'
export const SMART_ORDER_QUANTITATIVE: string = 'quantitative-analysis'
export const SMART_ORDER_QUALITATIVE: string = 'qualitative-analysis'
export const SMART_ORDER_RECOMMENDATION_ORDER: string = 'recommendation-order'
export const SMART_ORDER_DEMAND_FORECAST: string = 'demand-forecast'

// INTELLIGENT PRICING
export const PRICING: string = 'intelligent-pricing'
export const PRICING_RESULTS: string = 'results'
export const PRICING_REGISTRATION_POSITIONING: string =
  'registration-positioning'
export const PRICING_PRICE_POSITIONING: string = 'price-positioning'
export const PRICING_LAST_CAMPAIGN: string = 'last-campaign'
export const PRICING_CREATE_CAMPAIGN: string = 'create-campaign'
export const PRICING_NEW_CAMPAIGN: string = 'new-campaign'
export const DATA_FORECAST_INSIGHTS: string = 'data-forecast-insights'
export const POCKET_ANALYSIS: string = 'pocket-analysis'
export const MTF_COMPARATIVE: string = 'multivariant-graph'
export const DEMAND_FORECAST: string = 'demand-forecast'
export const DASHBOARD: string = 'dashboard'
